<template>
  <v-container>
    
    <v-card>
      <v-text-field single-line hide-details dense label="ค้นหาสถานที่" solo flat prepend-inner-icon="mdi-magnify" class="mb-4"
          @keypress.enter="getLocationFromPlace" placeholder="พิมพ์ข้อความและกด Enter" v-model="place"></v-text-field>
        <!-- <input type="text" v-model="place" @keypress.enter="searchLocations" placeholder="พิมพ์ข้อความและกด"> -->
        <ul v-if="suggestions.length">
          <li class="suggest-item" v-for="(suggestion, index) in suggestions" :key="index" @click="selectLocation(suggestion)">
            {{ suggestion.name }}
          </li>
        </ul>
        <!-- <v-menu v-if="suggestions.length" open-on-hover :close-on-click="false" :close-on-content-click="false">
          <v-list>
            <v-list-item v-for="(suggestion, index) in suggestions" :key="index" @click="selectLocation(suggestion)">
              <v-list-item-content>
                {{ suggestion }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
    </v-card>
    <v-card :loading="loading" class="mb-4">
      <!-- <v-container>  -->
      <v-toolbar absolute floating dense class="mt-4 ml-4">
        <!-- <v-text-field single-line hide-details dense label="ค้นหาสถานที่" solo flat prepend-inner-icon="mdi-magnify"
          @keypress.enter="getLocationFromPlace" v-model="place"></v-text-field>
        <v-btn icon small @click="currentPosition">
          <v-icon>mdi-crosshairs-gps</v-icon>
        </v-btn> -->
        <!-- <v-menu open-on-hover :close-on-click="false" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-text-field label="ระยะทาง(ก.ม.)" hint="ระยะทางที่ต้องการแสดงจุดตรวจโดยรอบ"
                  v-model="distance"></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-toolbar> 
      <!-- <gmap-map
        :center="mapCenter"
        :zoom="12"
        :options="gmapOptions"
        map-type-id="roadmap"
        :style="mapStyles"
        @click="setPosition"
        @zoom_changed="zoomChanged"
        ref="map"
      >
        <gmap-marker
          v-if="mapLat != null && mapLng != null"
          :position="{ lat: mapLat, lng: mapLng }"
          :icon="markerIcon(markerType)"
        />
        <gmap-marker
          v-for="m in nearbyMarker"
          :title="
            `${m.title} 
            (${moment(m.start_at)
              .add(543, 'year')
              .format('DD MMMYY HH:mm')} - ${moment(m.end_at).format('HH:mm')})`
          "
          :position="m.position"
          :icon="nearbyCone"
          :key="m.id"
        />
      </gmap-map> -->
      <longdo-map id="map" 
        @load="loadMap" 
        :zoom="16" 
        :lat="mCenter.lat"
        :lon="mCenter.lon" 
        :lastView="false" 
        v-if="visible" 
        :marker="marker">
      </longdo-map>

      <!-- <v-text-field label="ที่ตั้งจุดตรวจ" v-model="mapAddress"></v-text-field> -->
      <!-- <div id="map"></div> -->
      <!-- </v-container> -->
    </v-card>


    <!-- แสดงข้อความ จำนวนจุดตรวจโดยรอบ -->
    <v-toolbar v-show="nearbyMarker.length > 0" class="mb-4" color="blue darken-4" dark height="45" width="100%" rounded
      elevation="3">
      <v-icon>
        mdi-alert
      </v-icon>
      มี {{ nearbyMarker.length }} จุดตรวจ ในรัศมี {{ nearByDistance }} กม. โดยรอบจุดตรวจที่เลือก
      <v-spacer></v-spacer>
      <v-btn small @click="fitBounds">
        <v-icon small>
          mdi-arrow-expand-all
        </v-icon>
        แสดงทั้งหมด
      </v-btn>
    </v-toolbar>

    <!-- form -->
    <v-form v-model="formValid" ref="form" lazy-validation>
      <v-row class="pb-0">
        <v-col>
          <v-text-field label="ที่ตั้งจุดตรวจ" v-model="mapAddress" ref="mapAddress"
            hint="* กรอกชื่อสถานที่ หรือ หากเลือกจากแผนที่ (ข้อมูลจะแสดง ละติจูด ลองจิจูด โดยอัตโนมัติ)"
            :rules="reqRule"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col>
          <v-text-field label="ถนน" v-model="mapRoad" :rules="reqRule" hint="* กรอกชื่อถนน"></v-text-field>
        </v-col>
        <v-col>
          <v-select label="ขาเข้า/ขาออก" :items="roadDirection" v-model="mapInbound" :rules="reqRule"
            autocomplete></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select label="กล้อง CCTV" v-model="cctvSelected" :items="cameras" multiple
            hint="* เลือกได้หลายตัว กรณีติดตั้งมากกว่า 1 ตัว"></v-select>
        </v-col>
      </v-row>
    </v-form>

    <bjp-confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import coneMarker from '@/assets/cone-marker.png'
import alcoholMarker from '@/assets/alcohol-marker.png'
import speedCamMarker from '@/assets/speed-camera-marker.png'
import nearbyCone from '@/assets/cone-marker-nearby.png'
import { gmapApi } from 'gmap-vue'
import { getAddress } from '@/api/google'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { selectBox } from '@/api/camera'
import { LongdoMap } from 'longdo-map-vue'

// LongdoMapLoad({
//   apiKey: '7ae54d646e284c217f1cebe595e0f448',
// })

// LongdoMap.init('7ae54d646e284c217f1cebe595e0f448')

export default {
  methods: {
    setMapAddress(value) {
      this.mapAddress = value;
    },
    localtionChange(map) {
      var location = map.location(); // Cross hair location


      this.mapLat = location.lat;
      this.mapLng = location.lon;

      map.Overlays.clear();

      var marker = new longdo.Marker({ lon: location.lon, lat: location.lat });
      map.Overlays.add(marker);

      // this.mapAddress = location.lat + ', ' + location.lon;
    },
    async searchLocations() {
      if (!this.query.trim()) {
        this.suggestions = [];
        return;
      }

      try {
        const response = await fetch(`https://search.longdo.com/mapsearch/json/search?keyword=${this.query}&area=10&span=100km&limit=20&key=7ae54d646e284c217f1cebe595e0f448`);
        const data = await response.json();
        this.suggestions = data?.data?.map(item => ({name: item.name, lat: item.lat, lng: item.lng}));
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    selectLocation(selectedLocation) {
      
      this.query = selectedLocation;
      this.suggestions = [];
      // You can do further processing with the selected location
      // For example, display it on the map
      this.displayLocationOnMap(selectedLocation);
    },
    displayLocationOnMap(location) {
      // Update map marker to display selected location
      this.marker = {
        lat: location.lat,
        lon: location.lon,
        icon: {
          offset: { x: 16, y: 32 },
        },
      };
      this.mCenter = { lat: location.lat, lon: location.lon };

      this.mapLat = location.lat;
      this.mapLng = location.lon;

      this.map.Overlays.clear();

      var marker = new longdo.Marker({ lon: location.lon, lat: location.lat });
      this.map.Overlays.add(marker);

      this.locationName = location.name;

      this.map.location({ lat: location.lat, lon: location.lon });

      // this.mapRoad = location.name

    },
    loadMap(map) {

      map.Overlays.clear();

      map.Layers.setBase(longdo.Layers.NORMAL);
      map.Layers.add(longdo.Layers.TRAFFIC);

      if (this.lat && this.lng) {
        map.location({ lon: this.lng, lat: this.lat }, true);
        var marker = new longdo.Marker({ lon: this.lng, lat: this.lat });
        map.Overlays.add(marker);
        // this.mapAddress = this.lat + ', ' + this.lng
      } else {
        map.location(longdo.LocationMode.Geolocation);
      }

      // setMapAddress()

      // this.localtionChange(map)

      // map.Event.bind('location', this.localtionChange(map));

      var _this = this

      map.Event.bind('location', function () {

        var location = map.location(); // Cross hair location

        // map.Search.language('th');

        _this.mapLat = location.lat;
        _this.mapLng = location.lon;

        map.Overlays.clear();

        var marker = new longdo.Marker({ lon: location.lon, lat: location.lat });
        map.Overlays.add(marker);

        _this.mapAddress = location.lat + ', ' + location.lon;

        // _this.mapRoad = route.long_name

        _this.$emit('position-updated', {
          lat: location.lat,
          lng: location.lon,
          // distance: this.distance,
        });

      })

      this.map = map

      // map.Event.bind('click', function () {
      //   var location = map.location(); // Cross hair location
      //   // console.log(location);
      //   this.mapLat = location.lat;
      //   this.mapLng = location.lon;

      //   map.Overlays.clear();

      //   var marker = new longdo.Marker({ lon: location.lon, lat: location.lat });
      //   map.Overlays.add(marker);

      //   _this.mapAddress = location.lat + ', ' + location.lon;

      //   // _this.mapRoad = route.long_name

      //   _this.$emit('position-updated', {
      //     lat: location.lat,
      //     lng: location.lon,
      //     // distance: this.distance,
      //   })
      // });
    },
    setPosition(e) {
      // console.log(this.google.maps.ControlPosition.RIGHT_BOTTOM)
      //   console.log(e.latLng)
      this.mapLat = e.latLng.lat()
      this.mapLng = e.latLng.lng()
      this.$refs.map.$mapPromise.then(map => {
        map.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })
        map.setZoom(this.currentZoom < 11 ? 18 : this.currentZoom)
      })

      this.$emit('position-updated', {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        distance: this.distance,
      })
      getAddress({ lat: e.latLng.lat(), lng: e.latLng.lng() })
        .then(res => {
          const results = res.data.results
          //   console.log(results)
          // หาชื่อถนน
          let route = results
            .find(r => r.types.includes('route'))
            .address_components.find(a => a.types.includes('route'))
          // หาที่อยู่ตัดประเทศไทยออก
          let address = results
            .find(r => r.types.includes('route'))
            .address_components.filter(a => !a.types.includes('route'))
            .filter(a => !a.types.includes('country'))
            .map(a => a.long_name)
            .join(' ')
          this.mapAddress = address
          //   console.log(addressStr)
          this.mapRoad = route.long_name
          //   console.log(route)
        })
        .catch(err => console.log(err))
    },
    fitBounds() {
      let b = new this.google.maps.LatLngBounds()
      b.extend({ lat: this.mapLat, lng: this.mapLng })
      this.nearbyMarker.forEach(item => {
        b.extend(item.position)
      })

      this.$refs.map.fitBounds(b)
    },
    currentPosition() {
      // if (!('geolocation' in navigator)) return
      // this.loading = true
      // navigator.geolocation.getCurrentPosition(pos => {
      //   // console.log(pos)
      //   this.$refs.map.$mapPromise
      //     .then(map => {
      //       map.setCenter({
      //         lat: pos.coords.latitude,
      //         lng: pos.coords.longitude,
      //       })
      //     })
      //     .finally(() => {
      //       this.loading = false
      //     })
      // })
    },
    async getLocationFromPlace() {
      //longomap
      if (!this.place.trim()) {
        this.suggestions = [];
        return;
      }

      try {
        const response = await fetch(`https://search.longdo.com/mapsearch/json/search?keyword=${this.place}&limit=10&key=7ae54d646e284c217f1cebe595e0f448`);
        const data = await response.json();
        this.suggestions = data?.data;
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }

      
      // map.Search.search(search.value, {
      //gmap
      // let geocoder = new this.google.maps.Geocoder()
      // this.loading = true
      // geocoder.geocode({ address: this.place }, (results, status) => {
      //   if (status == 'OK') {
      //     this.$refs.map.$mapPromise
      //       .then(map => {
      //         map.setCenter(results[0].geometry.location)
      //       })
      //       .finally(() => {
      //         this.loading = false
      //       })
      //   } else {
      //     this.$refs.confirm.open('Error', 'ไม่สามารถค้นหาจุดจากคำค้นหานี้ได้ : ' + status, {
      //       color: 'red',
      //       noconfirm: true,
      //     })
      //     this.loading = false
      //   }
      // })
    },
    zoomChanged(zoom) {
      this.currentZoom = zoom
    },
    markerIcon(type) {
      switch (type) {
        case 1:
          return coneMarker
        case 2:
          return alcoholMarker
        case 3:
          return speedCamMarker
        default:
          return coneMarker
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    getCameras() {
      selectBox().then(res => {
        this.cameras = res
      })
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    ...mapMutations('Appfilter', ['setNearByDistance']),
  },
  data() {
    return {
      loading: false,
      position: null,
      nearbyCone: nearbyCone,
      place: '',
      roadDirection: [
        { value: 1, text: 'ขาเข้า' },
        { value: 2, text: 'ขาออก' },
      ],
      currentZoom: 10,
      gmapOptions: {
        zoomControl: true,
        zoomControlOptions: {
          position: 4,
        },
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 9, // this.google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      cameras: [],
      mapStyles: {
        width: '100hh',
        height: '400px',
      },
      reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
      reqRule2: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
      query: '',
      suggestions: [],
      marker: null,
      mCenter: { lat: 13.736717, lon: 100.523186 }, // Default map center,
      map: null,
      locationName: ''
    }
  },
  computed: {
    google: gmapApi,
    distance: {
      get() {
        return this.nearByDistance
      },
      set(v) {
        this.setNearByDistance(v)
      },
    },
    mapCenter() {
      if (this.mapLat == undefined || this.mapLat == null) return { lat: 13.1, lng: 101.2 }
      else return { lat: this.mapLat, lng: this.mapLng }
    },
    mapData: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    mapLat: {
      get() {
        return this.lat
      },
      set(v) {
        this.$emit('update:lat', v)
      },
    },
    mapLng: {
      get() {
        return this.lng
      },
      set(v) {
        this.$emit('update:lng', v)
      },
    },
    mapAddress: {
      get() {
        return this.address
      },
      set(v) {
        this.$emit('update:address', v)
      },
    },
    mapRoad: {
      get() {
        return this.road
      },
      set(v) {
        this.$emit('update:road', v)
      },
    },
    mapInbound: {
      get() {
        return this.inbound
      },
      set(v) {
        this.$emit('update:inbound', v)
      },
    },
    formValid: {
      get() {
        return this.valid
      },
      set(v) {
        this.$emit('update:valid', v)
      },
    },
    cctvSelected: {
      get() {
        return this.selectedCameras
      },
      set(v) {
        this.$emit('update:selectedCameras', v)
      },
    },
    ...mapState('Appfilter', ['nearByDistance']),
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    lat: {
      type: Number,
      default: null,
    },
    lng: {
      type: Number,
      default: null,
    },
    address: {},
    road: {},
    inbound: {},
    markerType: {},
    nearbyMarker: {},
    selectedCameras: {},
    visible: false
  },
  watch: {
    lat() {
      this.resetValidation()
    },
    // mapAddress() {
    //   console.log(this.mapAddress)
    // }
  },
  mounted() { },
  comments: {
    LongdoMap
  },
  created() {
    // this.loadMap()
    // this.loadMap = this.loadMap.bind(this);
    this.getCameras()
  },
}
</script>

<style>
.ldmap-vue-container {
  height: 400px !important;
}
.suggest-item {
  padding: 10px 5px;
  cursor: pointer;
}
.suggest-item:hover {
  background-color: aliceblue;
}
</style>
