<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-snackbar :timeout="2000" v-model="showAlert" absolute centered :color="snackColor">
      {{ message }}
    </v-snackbar>
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon>
          mdi-traffic-cone
        </v-icon>
        ข้อมูลจุดตรวจ
        <span class="grey--text subtitle-2"> {{ type == 'edit' ? '(แก้ไข)' : '' }}</span>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6">
            <cp-form
              ref="cpForm"
              :valid.sync="valid"
              :type="type"
              :allowDates="allowDates"
              v-model="checkpoint_edit"
              :officers-obj.sync="officers"
            ></cp-form
          ></v-col>
          <v-col cols="12" md="6">
            <!-- <map-form
              ref="mapForm"
              :address.sync="checkpoint_edit.address"
              :lat.sync="checkpoint_edit.lat"
              :lng.sync="checkpoint_edit.lng"
              :road.sync="checkpoint_edit.road"
              :inbound.sync="checkpoint_edit.inbound"
              :marker-type="checkpoint_edit.checkpoint_type_id"
              :valid.sync="valid"
              :nearby-marker="nearbyCheckpoints"
              :selectedCameras.sync="checkpoint_edit.cameras"
              @position-updated="getNearBy"
            ></map-form> -->
            <longdo-map-form
              ref="mapForm"
              :address.sync="checkpoint_edit.address"
              :lat.sync="checkpoint_edit.lat"
              :lng.sync="checkpoint_edit.lng"
              :road.sync="checkpoint_edit.road"
              :inbound.sync="checkpoint_edit.inbound"
              :marker-type="checkpoint_edit.checkpoint_type_id"
              :valid.sync="valid"
              :nearby-marker="nearbyCheckpoints"
              :selectedCameras.sync="checkpoint_edit.cameras"
              @position-updated="getNearBy"
              :visible="dialog"
            ></longdo-map-form>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn text @click="closeDialog">{{ type == 'edit' ? 'ปิด' : 'ยกเลิก' }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.stop="saveData" :disabled="!checkpoint.can.update" :loading="loading">
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
      </v-card-actions>
      <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import cpForm from './partials/form'
import mapForm from './partials/gmapForm'
import longdoMapForm from './partials/longdoMapForm'
import { show, nearby, store, update } from '@/api/checkpoint'
export default {
  methods: {
    saveData() {
      if (this.$refs.cpForm.validate() && this.$refs.mapForm.validate()) {
        if (this.type == 'edit') {
          // do edit
          this.checkpoint_edit.officers = this.officers
          this.loading = true
          update(this.checkpoint_edit.checkpoint_plan_id, this.checkpoint_edit)
            .then(res => {
              // console.log(res)
              this.showMessage('บันทึกข้อมูลเรียบร้อยแล้ว')
            })
            .catch(err => {
              if (err.response.status == 403) {
                this.$refs.confirm.open('Forbiden', 'จุดตรวจนี้มีการตั้งไปแล้ว ไม่สามารถแก้ไขได้', {
                  color: 'red',
                  noconfirm: true,
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
          return
        }

        // do add new
        let data = { officers: this.officers, ...this.checkpoint_edit }
        this.loading = true
        store(this.cmdId, data)
          .then(res => {
            this.nearbyCheckpoints = []
            this.checkpoint_edit = Object.assign({}, this.checkpoint)
            this.valid = true

            this.$emit('submit', res)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    initCheckpoint(checkpoint, type) {
      if (type == 'edit') {
        this.checkpoint_edit = Object.assign({}, checkpoint)
        // set date and time that accept by vuetify
        const time =
          this.checkpoint_edit.start_at.split(' ')[1].substr(0, 5) +
          '-' +
          this.checkpoint_edit.end_at.split(' ')[1].substr(0, 5)

        this.checkpoint_edit = { times: time, ...this.checkpoint_edit }
        // this.checkpoint_edit.times =

        this.checkpoint_edit.start_at = this.checkpoint_edit.start_at.substr(0, 10)
        // จัดการรายชื่อเจ้าหน้าที่สำหรับตารางรายการเจ้าหน้าที่
        this.officers = checkpoint.officers.map(o => ({
          officer_id: o.officer_id,
          officer_type_id: o.officer_type_id,
          remark: o.remark,
        }))

        this.checkpoint_edit.cameras = checkpoint.cameras.map(c => c.id)
      } else {
        this.checkpoint_edit = Object.assign({}, this.checkpoint)
        this.officers = []
      }
    },
    // สำหรับ เวลากดแก้ไขจาก Detail Dialog
    initCheckpoint2(checkpoint) {
      this.checkpoint_edit = Object.assign({}, checkpoint)
      // set date and time that accept by vuetify
      const time =
        this.checkpoint_edit.start_at.split(' ')[1].substr(0, 5) +
        '-' +
        this.checkpoint_edit.end_at.split(' ')[1].substr(0, 5)

      this.checkpoint_edit = { times: time, ...this.checkpoint_edit }
      this.checkpoint_edit.start_at = this.checkpoint_edit.start_at.substr(0, 10)
      // จัดการรายชื่อเจ้าหน้าที่สำหรับตารางรายการเจ้าหน้าที่
      this.officers = checkpoint.officers.map(o => ({
        officer_id: o.officer_id,
        officer_type_id: o.officer_type_id,
        remark: o.remark,
      }))

      this.checkpoint_edit.cameras = checkpoint.cameras.map(c => c.id)
    },

    getNearBy(pos) {
      let params = {
        dates: this.checkpoint_edit.start_at,
        ...pos,
      }
      // nearby(params).then(res => {
      //   this.nearbyCheckpoints = res
      // })
    },
    closeDialog() {
      this.checkpoint_edit = Object.assign({}, this.checkpoint)
      this.nearbyCheckpoints = []
      this.valid = true
      this.$emit('cancelClick')
    },
    showMessage(msg, color) {
      if (color == undefined) color = 'primary'
      this.snackColor = color
      this.message = msg
      this.showAlert = true
    },
  },
  data() {
    return {
      valid: true,
      cmdId: '',
      loading: false,
      checkpoint_edit: {},
      message: '',
      snackColor: 'primary',
      showAlert: false,
      checkpoint: {
        id: '',
        name: '',
        address: '',
        road: '',
        lat: null,
        lng: null,
        start_at: [], // ถ้าเป็นการเพิ่มข้อมูล start_at จะเป็น array เพื่อรับ date หลายวัน
        end_at: '',
        checkpoint_types: [],
        inbound: 1,
        remark: null,
        times: '',
        cameras: [],
        can: {
          update: true,
          delete: true,
          open: true,
        },
      },
      officerList: [],
      officerTypes: [],
      officers: [],
      nearbyCheckpoints: [],
    }
  },
  created() {
    this.cmdId = this.$route.params.id
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'add',
    },
    allowDates: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    cpForm,
    mapForm,
    longdoMapForm
  },
  watch: {
    '$route.params.id'(v) {
      this.cmdId = v
    },
  },
}
</script>

<style></style>
