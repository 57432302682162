var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',[_c('v-select',{attrs:{"label":"ประเภท (เลือกได้หลายแบบ)","items":_vm.filteredCheckpointTypes,"multiple":"","rules":_vm.reqRule,"required":""},model:{value:(_vm.checkpoint.checkpoint_types),callback:function ($$v) {_vm.$set(_vm.checkpoint, "checkpoint_types", $$v)},expression:"checkpoint.checkpoint_types"}}),_c('span',{staticClass:"subtitle-2"},[_vm._v("* เฉพาะประเภทกวดขันวินัยจราจร และ ตรวจวัดแอลกอฮอล์ จะสามารถเลือกได้เพียงจุดละประเภทเท่านั้น")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasErrorType),expression:"hasErrorType"}],staticClass:"red--text subtitle-2"},[_vm._v("* เลือกประเภทจุดตรวจอย่างน้อย 1 ประเภท")])],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"ชื่อจุดตรวจ","hint":"จุดตรวจหน้า... จุดตรวจแยก...","rules":_vm.reqRule,"required":""},model:{value:(_vm.checkpoint.name),callback:function ($$v) {_vm.$set(_vm.checkpoint, "name", $$v)},expression:"checkpoint.name"}})],1)],1),_c('v-row',{staticClass:"pb-0"},[_c('v-col',[_c('v-menu',{ref:"datemenu",attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.type == 'add' ? _vm.reqArrRule : _vm.reqRule,"label":_vm.type == 'add' ? 'วันที่(เลือกได้หลายวัน)' : 'วันที่',"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datemenu),callback:function ($$v) {_vm.datemenu=$$v},expression:"datemenu"}},[_c('v-date-picker',{attrs:{"multiple":_vm.type == 'add' ? true : false,"min":_vm.min,"max":_vm.max,"show-current":_vm.min,"no-title":""},model:{value:(_vm.checkpoint.start_at),callback:function ($$v) {_vm.$set(_vm.checkpoint, "start_at", $$v)},expression:"checkpoint.start_at"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.datemenu = false}}},[_vm._v("ตกลง")])],1)],1)],1),_c('v-col',[_c('v-text-field-simplemask',{attrs:{"label":"ช่วงเวลา","properties":{
          prefix: '',
          suffix: '',
          rules: _vm.timeRules,
          readonly: false,
          disabled: false,
          outlined: false,
          clearable: true,
          placeholder: '00:00-00:00',
        },"options":{
          inputMask: '##:##-##:##',
          outputMask: '##:##-##:##',
          empty: '',
          applyAfter: false,
          alphanumeric: true,
          lowerCase: false,
        }},model:{value:(_vm.checkpoint.times),callback:function ($$v) {_vm.$set(_vm.checkpoint, "times", $$v)},expression:"checkpoint.times"}})],1)],1),_c('v-row',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"label":"เลือกชุด","disabled":_vm.groups.length == 0,"items":_vm.groups},model:{value:(_vm.groupSelected),callback:function ($$v) {_vm.groupSelected=$$v},expression:"groupSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-autocomplete',{attrs:{"label":"เจ้าหน้าที่","loading":_vm.officers.length == 0,"items":_vm.officers,"rules":_vm.reqArrRule,"return-object":false,"multiple":"","clearable":""},model:{value:(_vm.officerSelected),callback:function ($$v) {_vm.officerSelected=$$v},expression:"officerSelected"}})],1)],1),_c('v-row',{staticClass:"pa-0",attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasChief),expression:"!hasChief"}],staticClass:"red--text subtitle-2"},[_vm._v("* เลือกหัวหน้าจุดตรวจ")]),_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.officerSelectedObject),function(off,i){return _c('tr',{key:("off-" + (off.officer_id))},[_c('td',[_vm._v(_vm._s(_vm.officerInfo(off.officer_id)))]),_c('td',[_c('v-select',{attrs:{"label":"ทำหน้าที่","items":_vm.officerTypes},model:{value:(off.officer_type_id),callback:function ($$v) {_vm.$set(off, "officer_type_id", $$v)},expression:"off.officer_type_id"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"หมายเหตุ"},model:{value:(off.remark),callback:function ($$v) {_vm.$set(off, "remark", $$v)},expression:"off.remark"}})],1),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeOfficer(i)}}},[_vm._v(" mdi-delete ")])],1)])}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }